var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CRow',{staticClass:"mt-2"},[_c('loading-overlay',{attrs:{"active":_vm.loading,"is-full-page":true,"loader":"bars"}}),_c('CCol',{attrs:{"sm":"12","lg":"12","xl":"12"}},[_c('CRow',[_c('CCol',{staticClass:"d-flex justify-content-end py-2",attrs:{"sm":"12"}},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                    content: _vm.$t('label.eirType'),
                    placement: 'top-end'
                    }),expression:"{\n                    content: $t('label.eirType'),\n                    placement: 'top-end'\n                    }"}],staticClass:"d-flex align-items-center",attrs:{"square":"","color":"add"},on:{"click":_vm.toggleTypeEirCreate}},[_c('CIcon',{attrs:{"name":"cil-playlist-add"}}),_vm._v("  "+_vm._s(_vm.$t('label.nuevo'))+" ")],1)],1)],1),_c('CRow',[_c('CCol',{staticClass:"content-table-modal-tp-eir",attrs:{"sm":"12"}},[_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"items":_vm.computedTpEirList,"fields":_vm.tpfields,"column-filter":"","items-per-page-select":_vm.tableText.itemsPerPageText,"items-per-page":5,"pagination":"","sorter":"","tableFilter":_vm.tableText.tableFilterText,"noItemsView":_vm.tableText.noItemsViewText},scopedSlots:_vm._u([{key:"Status",fn:function({ item }){return [_c('td',{staticClass:"text-center"},[_c('CBadge',{attrs:{"color":_vm.getBadge(item.Status)}},[_vm._v(" "+_vm._s(_vm.$t('label.'+item.Status))+" ")])],1)]}},{key:"options",fn:function({ item }){return [_c('td',{staticClass:"text-center"},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                            content: _vm.$t('label.edit')+' '+_vm.$t('label.eirType'),
                            placement: 'top-end'
                          }),expression:"{\n                            content: $t('label.edit')+' '+$t('label.eirType'),\n                            placement: 'top-end'\n                          }"}],attrs:{"size":"sm","square":"","color":"edit"},on:{"click":function($event){return _vm.toggleTypeEirEdit(item)}}},[_c('CIcon',{attrs:{"name":"pencil"}})],1)],1)]}}])})],1)],1)],1),_c('modal-create-type-eir',{attrs:{"modal":_vm.modalTypeEirCreate},on:{"update:modal":function($event){_vm.modalTypeEirCreate=$event},"set-modal-type-eir-list":_vm.setEirList}}),_c('modal-edit-type-eir',{attrs:{"modal":_vm.modalTypeEirUpdate,"typeEirSelect":_vm.typeEirSelect},on:{"update:modal":function($event){_vm.modalTypeEirUpdate=$event},"set-modal-edit-type-eir-list":_vm.setEirList}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }