<template>
      <CRow class="mt-2">
        <loading-overlay :active="loading" :is-full-page="true" loader="bars"/>
        <CCol sm="12" lg="12" xl="12">
              <CRow>
                <CCol sm="12" class="d-flex justify-content-end py-2">
                  <CButton
                    square
                    color="add"
                    class="d-flex align-items-center"
                    @click="toggleTypeEirCreate"
                    v-c-tooltip="{
                    content: $t('label.eirType'),
                    placement: 'top-end'
                    }"
                  >
                  <CIcon name="cil-playlist-add"/>&nbsp;
                    {{$t('label.nuevo')}}
                  </CButton>
                </CCol>
              </CRow>
              <CRow>
                <CCol sm="12" class="content-table-modal-tp-eir">
                  <dataTableExtended
                    class="align-center-row-datatable"
                    :items="computedTpEirList"
                    :fields="tpfields"
                    column-filter
                    :items-per-page-select="tableText.itemsPerPageText"
                    :items-per-page="5"
                    pagination
                    sorter
                    :tableFilter="tableText.tableFilterText"
                    :noItemsView="tableText.noItemsViewText"
                  >
                    <template #Status="{ item }">
                      <td class="text-center">
                        <CBadge :color="getBadge(item.Status)">
                          {{ $t('label.'+item.Status) }}
                        </CBadge>
                      </td>
                    </template>
                    <template #options="{ item }">
                      <td class="text-center">
                        <CButton
                          size="sm"
                          square
                          color="edit"
                          @click="toggleTypeEirEdit(item)"
                          v-c-tooltip="{
                            content: $t('label.edit')+' '+$t('label.eirType'),
                            placement: 'top-end'
                          }"
                        >
                          <CIcon name="pencil" />
                        </CButton>
                      </td>
                    </template>
                  </dataTableExtended>
                </CCol>
               </CRow>
        </CCol>
    <modal-create-type-eir
      :modal.sync="modalTypeEirCreate"
      @set-modal-type-eir-list="setEirList"
    />
    <modal-edit-type-eir
      :modal.sync="modalTypeEirUpdate"
      @set-modal-edit-type-eir-list="setEirList"
      :typeEirSelect="typeEirSelect"
    />
</CRow>
</template>
<script>
import ModalCreateTypeEir from './modal-create-type-eir';
import ModalEditTypeEir from './modal-edit-type-eir';
import { DateFormater } from '@/_helpers/funciones';
import GeneralMixin from '@/_mixins/general';
import CustomTabs from '@/components/tabs/CustomTabs';
import CustomTab from '@/components/tabs/CustomTab';

function data() {
  return {
    items: [],
    TpEirItems: [],
    modalTypeEirCreate: false,
    modalTypeEirUpdate: false,
    typeEirSelect: {},
    loading: false,
  };
}
function handleTab(tab) {
  this.Tab = tab;
}


function toggleTypeEirCreate() {
  this.modalTypeEirCreate = !this.modalTypeEirCreate;
}

function toggleTypeEirEdit(item) {
  this.typeEirSelect = Object.assign({}, item);
  this.modalTypeEirUpdate = !this.modalTypeEirUpdate;
}

async function setEirList() {
  this.getEirList();
}

function getEirList() {
  this.loading = true;
  this.$http
    .get('TpEir-list')
    .then((response) => (
      this.TpEirItems = response.data.data
    ))
    .finally(() => {
      this.loading = false;
    });  
}

function computedTpEirList() {
  return this.TpEirItems.map((item) => {
    return {
      ...item,
      _classes: item.Status != 'ACTIVO' ? 'table-danger' : '',
      FormatedDate: item.TransaRegDate
        ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate)
        : 'N/A',
    };
  });
}
function tpfields(){
  return [
     { label: '#', key: 'Nro', _classes: 'text-center', _style: 'width:3%; text-align:center', filter: false},
     { key: 'TpEirNameEs',label: this.$t('label.eirType')+' '+'(ES)', _classes: 'text-uppercase text-center', _style: 'width:27%;',},
     { key: 'TpEirNameEn',label: this.$t('label.eirType')+' '+'(EN)', _classes: 'text-uppercase text-center', _style: 'width:27%;',},
     { key: 'TransaLogin',label: this.$t('label.user'), _classes: 'text-uppercase text-center', _style:'width:10%' },
     { key: 'FormatedDate',label: this.$t('label.date'),_classes:'text-center text-center', _style:'width:10%' },
     { key: 'Status',label: this.$t('label.status'), _classes: 'text-center text-uppercase', _style:'width:10%' },
     { label: '', key: 'options', _style: 'width:5%; min-width:45px;', sorter: false, filter: false },
  ];
}


export default {
  name: 'eir-index',
  components: { 
    ModalCreateTypeEir,
    ModalEditTypeEir,
    CustomTabs, 
    CustomTab,
  },
  data,
  props:{Tab: Number},
  mixins: [GeneralMixin],
  methods: {
    toggleTypeEirCreate,
    setEirList,
    toggleTypeEirEdit,
    getEirList,
    handleTab,
  },
  computed: {
    computedTpEirList,
    tpfields,
  },
  watch:{
    Tab:function (val){
      if(val == 0){
        this.getEirList();
      }
    }
  },
  mounted: getEirList,
};
</script>
<style lang="scss"></style>
